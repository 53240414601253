import React, { useEffect, useState } from 'react'
import styles from './Order.module.css'
// import OrderTable from '../../Utills/Table/OrderTable';
import Navbar from '../../Components/Navbar/Navbar';
import OrderAddModal from '../../Utills/Modal/OrderAddModal';
import axios from 'axios';
import OrderTable from '../../Utills/Table/OrderTable';
import EditOrderModal from '../../Utills/Modal/EditModal/EditOrderModal';
import add_icon from '../../Assets/uttils/add_button.svg'
import ExportExcel from '../../excelexport';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Link } from 'react-router-dom';

const Order = ({ orderscatch }) => {
  const [isModal, setIsModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState('');
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState(orderscatch);
  const [drivers, setDriver] = useState([]);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [areacontainers, setAreaContainers] = useState([]);
  const [areacontainer, setAreaContainer] = useState('');
  const [user, setUser] = useState('');
  const [status, setStatus] = useState('');
  const [excelData, setExcelData] = useState([{ утас: '', хаяг: '', дэлгүүр: '', төлөв: '', 'нийт дүн': '', 'захиалга үүсгэсэн огноо': '', 'үйлдэл дарсан огноо': '', огноо: '', жолооч: '' }])
  const [isEndCalendar, setIsEndCalendar] = useState(false);
  const [isStartCalendar, setIsStartCalendar] = useState(false);
  const [startValue, setStartValue] = useState(dayjs());
  const [endValue, setEndValue] = useState(dayjs());
  const [phone, setPhone] = useState('');
  const [isSearch, setSearch] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const closeModal = () => {
    setIsModal(false);
    setEditModal(false);
  }

  useEffect(() => {
    window.localStorage.setItem('side-id', 1);
    if (isLoading) {
      axios.get('https://dev.turbodelivery.mn/user?sort=-createdAt&role=driver')
        .then((e) => {
          setDriver(e.data.data);
        })
        .catch((e) => console.log(e));

      axios.get('https://dev.turbodelivery.mn/areacontainer')
        .then((e) => {
          setIsLoading(false);
          setAreaContainers(e.data.data);
        })
        .catch((e) => console.log(e));

      axios.get('https://dev.turbodelivery.mn/user?role=user')
        .then((e) => {
          setUsers(e.data.data);
        });

      // axios.get('https://dev.turbodelivery.mn/shop')
      //   .then((e) => {
      //     setShops(e.data.data.filter((e) => e.visible !== 'true'));
      //   });

      // axios.get('https://dev.turbodelivery.mn/order?sort=-createdAt')
      //   .then((order) => {
      //     setOrders(order.data.data);
      //   })
      //   .catch((e) => console.log(e));
    }

    // if (!isLoading) {
    const exceldata =
      orders
        .filter((e) => isSearch ?
          (
            (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(endValue, 'date') ||
              dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isBefore(endValue))
            &&
            (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(startValue, 'date') ||
              dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isAfter(startValue))
          )
          // dayjs(e.createdAt).isSame(endValue)
          : e)
        .map((el) => ({
          утас: el.phone,
          хаяг: el.address,
          дэлгүүр: users.find((e) => e._id === el.user)?.shop,
          төлөв: el.status,
          бүс: el.area,
          'нийт дүн': el.price,
          'захиалга үүсгэсэн огноо': dayjs(el.createdAt).format('YYYY-MM-DD'),
          'үйлдэл дарсан огноо': dayjs(el.orderDate).subtract(8, 'hour').format('YYYY-MM-DD'),
          жолооч: drivers.find((catchdriver) => catchdriver._id === el.driver)?.name,
        }));
    setExcelData(exceldata);
    // }


  }, [isLoading, drivers, orders, isSearch, endValue, startValue, shops, isCreated])

  const edit = ({ id, user }) => {
    setEditId(id);
    setUserId(user);
    setEditModal(true);
  }

  return (
    <>
      {!isModal ? <Navbar title={'Захиалга'} /> : <></>}
      {isModal ? <div className='abs'><OrderAddModal ordersCatch={orderscatch} closeModal={closeModal} /></div> : <></>}
      {editModal ? <div className='abs'><EditOrderModal userId={userId} id={editId} closeModal={closeModal} /></div> : <></>}
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='line-start'>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
          {/* <button className='button-white'>See Item</button> */}
          {/* <button className='button-white'>Print</button> */}
          <ExportExcel fileName={'name'} excelData={excelData} />
          <label>
            Дэлгүүрээр шүүх:
            <select name="" id="" onChange={(e) => setUser(e.target.value)}>
              <option value=''>Бүгд</option>
              {users.map((e) => (
                <option value={e._id}>{e.shop}</option>
              ))}
            </select>
          </label>
          <label>
            Төлвөөр шүүх:
            <select name="" id="" onChange={(e) => setStatus(e.target.value)}>
              <option value=''>Бүгд</option>
              <option value='Хүлээгдэж байна'>Хүлээгдэж байна</option>
              <option value='Буцаагдсан'>Буцаагдсан</option>
              <option value='Жолооч авсан'>Жолооч авсан</option>
              <option value='Маргааш авах'>Маргааш авах</option>
              <option value='Хойшлуулсан'>Хойшлуулсан</option>
              <option value='Хүргэсэн'>Хүргэсэн</option>
              <option value='Хаягаар очсон'>Хаягаар очсон</option>
              <option value='Хэсэгчлэн хүргэсэн'>Хэсэгчлэн хүргэсэн</option>
            </select>
          </label>
        </div>
        <br />
        <div className="line-start">
          <label>
            Агуулхаар шүүх:
            <select name="" id="" onChange={(e) => setAreaContainer(e.target.value)}>
              <option value=''>Бүгд</option>
              {areacontainers.map((e) => (
                <option value={e._id}>{e.name}</option>
              ))}
            </select>
          </label>
          <label>
            Дугаараар хайх:
            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} name="" id="" />
          </label>
          <Link to={'/orderitems'} className='button-white'>
            Бараагаар харах
          </Link>
        </div>
        <br />
        <div className="line-start">
          {isStartCalendar ? (
            <div className="calendar-div" style={{ zIndex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                  <DemoItem>
                    <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                    <DateCalendar value={startValue}
                      onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          ) : (
            <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
              {/* End date: */}
              Эхлэх хугацаа:
              <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
            </label>
          )}
          {isEndCalendar ? (
            <div className="calendar-div" style={{ zIndex: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                  <DemoItem>
                    <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                    <DateCalendar value={endValue}
                      onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          ) : (
            <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
              {/* End date: */}
              Дуусах хугацаа:
              <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
            </label>
          )}

          {isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
            onClick={() => setSearch(false)}
          >
            Бүгдийг харах
          </button> :
            <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
              onClick={() => setSearch(true)}
            >
              Хайх
            </button>}
          {isCreated && isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
            onClick={() => setIsCreated(false)}
          >
            Үйлдэл дарсан огноо
          </button> : isSearch &&
          <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
            onClick={() => setIsCreated(true)}
          >
            Үүсгэсэн огноо
          </button>}
        </div>
        <br />
        <div>
          {/* <OrderItemTable orders={orders}/> */}
          <OrderTable orders={orders
            .filter((e) => phone ? e.phone.includes(phone) : e)
            .filter((e) => isSearch ?
              (
                (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(endValue, 'date') ||
                  dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isBefore(endValue))
                &&
                (dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isSame(startValue, 'date') ||
                  dayjs(isCreated ? e.createdAt : dayjs(e.orderDate).subtract(8, 'hour')).isAfter(startValue))
              )
              // dayjs(e.createdAt).isSame(endValue)
              : e)
            .filter((e) => status ? e.status === status : e)
            .filter((e) => areacontainer ? e.container === areacontainer : e)
          }
            drivers={drivers} edit={edit} user={users} filteruser={user} />
        </div>
      </div >
    </>
  )
}

export default Order
